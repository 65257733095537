<script lang="ts">
	import { onMount, tick } from "svelte";
	import { Button } from "svelma";
	import { fade, fly } from "svelte/transition";
	import SvgIcon from "@/common/svg-icon.svelte";

	export let logourl = "";

	let triggers: HTMLElement[];
	let isActive = false;
	let hasFocused = false;

	onMount(() => {
		triggers = Array.from(document.querySelectorAll(".site-header__program-nav .nav-link, .site-header__shortcut-nav .nav-link"));

		document.getElementById("menuToggleButton")?.addEventListener("click", () => {
			isActive = !isActive;
		});

		//document.addEventListener("click", onShortcutClick);
	});

	async function onShortcutClick(e: Event) {
		let target = e.target as HTMLElement;
		if (!target.hasAttribute("data-id")) {
			return;
		}

		let id = target.getAttribute("data-id")!;
		let trigger = triggers.find(e => e.getAttribute("data-id") === id);
		if (trigger == null) {
			return;
		}

		isActive = true;
		await tick();
		document.querySelector(`.navigation-overlay .nav-item[data-id='${id}']`)?.classList.add("is-focused");
		hasFocused = true;
	}

	function close() {
		isActive = false;
		hasFocused = false;
		for (let element of document.querySelectorAll(".is-focused")) {
			element.classList.remove("is-focused");
		}
	}
</script>

{#if isActive}
	<div class="navigation-overlay" class:has-focused={hasFocused} transition:fly={{ y: -200, duration: 600 }}>
		<a href="/" class="navigation-overlay__logo-container">
			<img class="site-header__logo" src={logourl} alt="Informatiktage Logo" />
		</a>

		<nav class="navigation-overlay__content">
			<slot />
		</nav>

		<div class="navigation-overlay__buttons">
			<button class="button is-info is-circle" on:click={close}>
				<SvgIcon name="interface-delete-1" />
			</button>
		</div>
	</div>
{/if}
