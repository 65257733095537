<script lang="ts">
	import tippy from "svelte-tippy";
	import SvgIcon from "@/common/svg-icon.svelte";
	import { favoriteIds } from "./store";

	export let listUrl: string = "";
</script>

<a class="button is-primary is-circle site-header__favorite-button" aria-label="Merkliste öffnen" href={listUrl} use:tippy={{ content: "Favoriten" }}>
	<span class="badge is-bottom-right is-dark">{$favoriteIds.length}</span>
	<SvgIcon name="interface-favorite-heart" />
</a>
