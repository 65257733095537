import { DateTime } from "luxon";

export const luxonSwissGermanLocale = { locale: "de-ch" };

export function formatDateTime(datetimeIso: string, format: Intl.DateTimeFormatOptions): string {
	return DateTime.fromISO(datetimeIso).toLocaleString(format, luxonSwissGermanLocale);
}

export function formatTime(datetimeIso: string, includeZeroMinutes: boolean = false) {
	var datetime = DateTime.fromISO(datetimeIso);
	return datetime.minute == 0 && !includeZeroMinutes
		? datetime.toLocaleString({ hour: "numeric" }, luxonSwissGermanLocale).replace(" Uhr", "")
		: datetime.toLocaleString({ hour: "numeric", minute: "2-digit" }, luxonSwissGermanLocale);
}

export function formatTimeRange(datetimeIsoBegin: string, datetimeIsoEnd?: string, includeZeroMinutes: boolean = false) {
	var end = datetimeIsoEnd ? DateTime.fromISO(datetimeIsoEnd) : null;
	if (end == null || (end.hour === 0 && end.minute === 0)) {
		return `ab ${formatTime(datetimeIsoBegin, includeZeroMinutes)}`;
	} else {
		return `${formatTime(datetimeIsoBegin, includeZeroMinutes)}–${formatTime(datetimeIsoEnd!, includeZeroMinutes)}`;
	}
}

export function calculateLogoWidthPercentage(aspectRatio: number): number {
	return -0.2 * Math.log(aspectRatio) + 0.2;
}

export enum FormState {
	Initial = "initial",
	Loading = "loading",
	Success = "success",
	Error = "error",
}
