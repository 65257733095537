<script lang="ts">
	import { tippy } from "svelte-tippy";
	import { Toast } from "svelma";
	import SvgIcon from "@/common/svg-icon.svelte";
	import { favoriteIds } from "./store";
	import { type Id } from "@/api";
	import { trackEvent } from "@/analytics";

	export let eventId: Id;
	export let eventName: string;
	export let companyName: string;

	$: isSelected = $favoriteIds.includes(eventId);
	$: tooltipText = isSelected ? "Aus Merkliste entfernen" : "Zu Merkliste hinzufügen";

	function toggle() {
		if (isSelected) {
			favoriteIds.update(ids => ids.filter(id => id !== eventId));
			Toast.create({ message: "Veranstaltung aus der Merkliste entfernt." });
			trackEvent("Merkliste", { props: { Name: eventName, Partner: companyName } });
		} else {
			favoriteIds.update(ids => [...ids, eventId]);
			Toast.create({ message: "Veranstaltung zur Merkliste hinzugefügt." });
		}
	}
</script>

<button class="button is-circle is-medium is-primary is-inverted" on:click|preventDefault={toggle} aria-label={tooltipText} use:tippy={{ content: tooltipText }}>
	{#if isSelected}
		<SvgIcon name="interface-favorite-heart" size="is-medium" />
	{:else}
		<SvgIcon name="interface-favorite-heart-outline" size="is-medium" />
	{/if}
</button>
