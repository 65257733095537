<script lang="ts">
	import tippy, { type TippyProps } from "svelte-tippy";

	export let tooltip: string | null = null;
	export let name: string;
	export let size = "";

	$: classNames = `icon ${size}`;
	$: svgHref = `#icon:${name}`;
	$: conditionalTippy = tooltip 
		? (element: Element, props?: TippyProps) => tippy(element as any, props)
		: (element: Element, props?: TippyProps) => {};
</script>

<svg class={classNames} use:conditionalTippy={{ content: tooltip ?? undefined }}>
	{#if tooltip != null && tooltip.length > 0}
		<title>{tooltip}</title>
	{/if}
	<use xlink:href={svgHref} />
</svg>
