import "../Styles/index.scss";

import component from "@wagich/svelte-tag";
import Flickity from "flickity";
import tippy from "tippy.js";

import NavigationOverlay from "./navigation-overlay.svelte";
new component({ component: NavigationOverlay, tagname: "navigation-overlay" });

import FavoriteButton from "./favorites/favorite-button.svelte";
new component({ component: FavoriteButton, tagname: "favorite-button" });

import FavoriteListButton from "./favorites/favorite-list-button.svelte";
new component({ component: FavoriteListButton, tagname: "favorite-list-button" });

import NewsletterSignup from "./newsletter/newsletter-signup.svelte";
new component({ component: NewsletterSignup, tagname: "newsletter-signup" });

if (document.querySelector("bluc-map") != null) {
	import("./bluc-map.svelte").then(m => new component({ component: m.default, tagname: "bluc-map" }));
}
if (document.querySelector("favorite-list") != null) {
	import("./favorites/favorite-list.svelte").then(m => new component({ component: m.default, tagname: "favorite-list" }));
}
if (document.querySelector("event-registration") != null) {
	import("./events/event-registration.svelte").then(m => new component({ component: m.default, tagname: "event-registration", loadJsonProps: true }));
}
if (document.querySelector("host-registration") != null) {
	import("./host-registration/host-registration.svelte").then(m => new component({ component: m.default, tagname: "host-registration", loadJsonProps: true }));
}
if (document.querySelector("event-filter") != null) {
	import("./events/event-filter.svelte").then(m => new component({ component: m.default, tagname: "event-filter", loadJsonProps: true }));
}

tippy("[data-tippy-content]");

let flickityElements = document.querySelectorAll("[data-carousel]");
for (let element of flickityElements) {
	if (element.childElementCount <= 1) {
		continue;
	}

	new Flickity(element, {
		arrowShape: "m93.89,50.4c0,3.53-2.87,6.4-6.4,6.4h-46.43v15.8c0,5.4-6.31,8.4-10.49,4.9-9.79-8.18-15.39-13.94-23.62-24.08-1.43-1.76-1.43-4.29,0-6.05,8.22-10.13,13.83-15.89,23.62-24.08,4.18-3.49,10.49-.49,10.49,4.91v15.8h46.43c3.53,0,6.4,2.87,6.4,6.4Z"
	});
}

import { enableAutoOutboundTracking, trackPageview } from "./analytics";
trackPageview();
enableAutoOutboundTracking();
