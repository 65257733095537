import type { FeatureCollection, Point } from "geojson";

interface IdBrand {
	_type: "Id";
}
export type Id = string & IdBrand;

export interface ProfilesResponse {
	companyProfiles: Profile[];
}

export interface EventFilterResponse {
	total: number;
	skip: number;
	count: number;
	salt: string;
	events: EventSearchResponse[];
	profiles: FeatureCollection<Point, MapProfile>;
}

export interface FilterDataPreload {
	firstDate: string;
	lastDate: string;
	companies: FilterDataPreloadCompany[]
}
export interface FilterDataPreloadCompany {
	id: Id;
	name: string;
}

export interface EventListResponse {
	events: EventSearchResponse[];
}

export class Profile {
	id: Id;
	url: string;
	name: string;
	partnerNumber: string;
	portraitSnippet: string;
	imageUrl: string;
	logoUrl: string;
	logoAspectRatio: number;
	eventCount: number;
	partnerType: PartnerType;
}

export class MapProfile {
	id: Id;
	url: string;
	title: string;
	partnerNumber: string;
}

export enum PartnerType {
	Basic = "basic",
	Startup = "startup",
	Premium = "premium",
	Supporting = "supporting"
}


export class EventRegistrationInfo {
	id: Id;
	companyName: string;
	name: string;
	eventKind: EventKind;
	dateTimes: EventRegistrationInfoDateTime[];
	maximumRegistrationCount?: number;
	registrationMode: RegistrationMode;
	studentTypes: StudentType[];
	isSoldOut: boolean;
}

export interface EventRegistrationInfoDateTime {
	begin: string;
	end: string;
	remainingRegistrationCount?: number;
}

export interface Address {
	streetAddress: string;
	postalCode: string;
	city: string;
	latitude: number;
	longitude: number;
}

export interface Image {
	id: Id;
	contentType: string;
	extension: string;
	length: number;
	width?: number | null;
	height?: number | null;
}

export class EventSearchResponse {
	id: Id;
	url: string;
	name: string;
	descriptionSnippet: string;
	logoUrl: string;
	logoAspectRatio: number;
	partnerNumber: number | null;
	companyName: string;
	imageUrl: string;
	street: string;
	postalCode: string;
	city: string;
	registrationMode: RegistrationMode;
	eventKind: EventKind;
	eventTypes: EventType[];
	visitorTypes: VisitorType[];
	studentTypes: StudentType[];
	venueTypes: VenueType[];
	dateTimes: EventDateTime[];
	isSoldOut: boolean;
	isRegistrationClosed: boolean;
	publishStatus: PublishStatus;
}

export enum PublishStatus {
	Draft = "draft",
	Published = "published",
}

export interface EventDateTime {
	begin: string;
	end: string;
}

export enum EventKind {
	Normal = "normal",
	SchoolClass = "schoolClass"
}

export enum EventType {
	Talk = "talk",
	Tour = "tour",
	Exhibition = "exhibition",
	Workshop = "workshop",
	Play = "play",
	WomenInIt = "womenInIt",
	Newcomer = "newcomer",
}

export enum VisitorType {
	Families = "families",
	Children = "children",
	Adolescents = "adolescents",
	Students = "students",
	Adults = "adults",
	Teachers = "teachers"
}

export enum VenueType {
	Host = "host",
	OnSite = "onSite",
	Virtual = "virtual",
}

export enum StudentType {
	ISCED0 = "isceD0",
	ISCED1 = "isceD1",
	ISCED2 = "isceD2",
	ISCED3And4 = "isceD3And4"
}

export enum RegistrationMode {
	Required = "required",
	Recommended = "recommended",
	NotRequired = "notRequired"
}

export interface EventRegistrationRequest {
	registrationKind: EventRegistrationKind;
	eventId: Id;
	dateTime: EventDateTime;

	fullname: string;
	organisation?: string | null;
	email: string;
	phone?: string | null;

	individualCount?: number | null;
	studentCount?: number | null;
	studentType?: StudentType | null;
	remarks?: string | null;

	signupForNewsletter: boolean;
}

export enum EventRegistrationKind {
	Individual = "individual",
	SchoolClass = "schoolClass"
}

export interface EventFilterCompany {
	id: Id;
	name: string;
	partnerNumber: number;
}

export interface NewsletterSignupRequest {
	fullname: string;
	email: string;
	interests: NewsletterInterest[];
}

export enum NewsletterInterest {
	General = "general",
	SchoolClassAndTeacher = "schoolClassAndTeacher"
}
