<script lang="ts">
	import SvgIcon from "@/common/svg-icon.svelte";
	import { onMount } from "svelte";
	import { create, test, enforce, only } from "vest";
	import "vest/enforce/email";
	import { ProblemDocument } from "http-problem-details";
	import ky from "ky";

	import { ValidatingStore } from "@/validating-store";
	import { FormState } from "@/utils";
	import { NewsletterInterest, type NewsletterSignupRequest } from "@/api";

	let state: FormState = FormState.Initial;
	let error: ProblemDocument | null = null;
	let token: string = "";

	const initialFormData: NewsletterSignupRequest = {
		fullname: "",
		email: "",
		interests: [NewsletterInterest.General]
	};
	const validationFunc = (data: NewsletterSignupRequest) => {
		test("fullname", "Bitte geben Sie Ihren Namen an.", () => {
			enforce(data.fullname).isNotBlank();
		});
		test("email", "Bitte geben Sie Ihre E-Mail-Adresse an.", () => {
			enforce(data.email).isNotBlank();
		});
		test("email", "Bitte geben Sie eine gültige E-Mail-Adresse an.", () => {
			enforce(data.email).isEmail();
		});
		test("interests", "Bitte wählen Sie mindestens ein Interessengebiet aus.", () => {
			enforce(data.interests)
				.isArray()
				.isNotEmpty();
		});
	};

	const store = new ValidatingStore(initialFormData, validationFunc);

	async function submit() {
		try {
			if (!store.validate()) {
				return;
			}

			state = FormState.Loading;
			let response = await ky.post(`/api/newsletter`, {
				headers: { RequestVerificationToken: token },
				json: store.getData(),
				throwHttpErrors: false
			});
			if (!response.ok) {
				error = await response.json<ProblemDocument>();
				state = FormState.Error;
				return;
			}

			state = FormState.Success;
		} catch (e) {
			error = { status: 0, title: "Client exception", detail: e!.toString() };
			state = FormState.Error;
		}
	}

	onMount(() => {
		let tokenElement = document.getElementById("RequestVerificationToken");
		if (tokenElement == null || tokenElement.textContent == null) {
			throw new Error("Could not load XSRF token.");
		}
		token = JSON.parse(tokenElement.textContent);
	});
</script>

<div class="columns is-align-items-flex-end mb-0">
	<div class="column is-4">
		<h2 class="title is-3 mb-2">Newsletter</h2>
		<p class="is-size-7">Bleiben Sie mit unserem Newsletter auf dem Laufenden. Wir informieren Sie gerne über Daten, Termine und spezielle Veranstaltungen.</p>
	</div>
	<div class="column">
		{#if state === FormState.Initial || state === FormState.Loading}
			<div class="field is-horizontal">
				<div class="field-body">
					<div class="field">
						<div class="control is-expanded">
							<label for="Newsletter_Fullname" class="is-sr-only">Name</label>
							<input id="Newsletter_Fullname" class="input" placeholder="Ihr Name" type="text" autocomplete="name" bind:value={$store.fullname.value} on:blur={() => $store.fullname.touch()} class:is-danger={$store.fullname.hasErrors} />
						</div>
						{#each $store.fullname.errors as error}
							<p class="help is-danger">{error}</p>
						{/each}
					</div>
					<div class="field">
						<div class="control">
							<label for="Newsletter_Email" class="is-sr-only">E-Mail</label>
							<input id="Newsletter_Email" class="input" placeholder="Ihre E-Mail-Adresse" type="email" autocomplete="email" bind:value={$store.email.value} on:blur={() => $store.email.touch()} class:is-danger={$store.email.hasErrors} />
						</div>
						{#each $store.email.errors as error}
							<p class="help is-danger">{error}</p>
						{/each}
					</div>
					<div class="field is-narrow">
						<p class="control">
							<button class="button is-primary" type="button" class:is-loading={state === FormState.Loading} on:click={submit}>Anmelden</button>
						</p>
					</div>
				</div>
			</div>
			<div class="field is-horizontal">
				<div class="field-body">
					<div class="field">
						<p class="control">
							<input type="checkbox" class="is-checkradio is-dark" id="Newsletter_Interest_General" value={NewsletterInterest.General} bind:group={$store.interests.value} on:blur={() => $store.interests.touch()} />
							<label for="Newsletter_Interest_General" class="is-size-7">Ich interessiere mich für das allgemeine Programm</label>
						</p>
						<p class="control mt-2">
							<input type="checkbox" class="is-checkradio is-dark" id="Newsletter_Interest_School" value={NewsletterInterest.SchoolClassAndTeacher} bind:group={$store.interests.value} on:blur={() => $store.interests.touch()} />
							<label for="Newsletter_Interest_School" class="is-size-7">Ich interessiere mich für das Programm für Schulkassen und Lehrpersonen</label>
						</p>
						{#each $store.interests.errors as error}
							<p class="help is-danger">{error}</p>
						{/each}
					</div>
				</div>
			</div>
		{/if}

		{#if state === FormState.Success}
			<div class="notification is-success is-light mb-0">
				<div class="columns is-mobile">
					<div class="column is-narrow">
						<SvgIcon size="is-large" name="interface-calendar-check" />
					</div>
					<div class="column">
						<p>Vielen Dank für Ihre Anmeldung. Wir haben Ihnen soeben ein Bestätigungsmail geschickt, bitte klicken Sie den darin enthaltenen Link an um Ihre Anmeldung zu bestätigen.</p>
					</div>
				</div>
			</div>
		{/if}

		{#if state === FormState.Error}
			<div class="notification is-danger is-light mb-0">
				<div class="columns is-mobile">
					<div class="column is-narrow">
						<SvgIcon size="is-large" name="interface-alert-warning-triangle" />
					</div>
					<div class="column">
						{#if error != null}
							<p>
								<strong>{error.title}</strong><br />
								{error.detail ?? error.toString()}
							</p>
						{:else}
							<p>Leider hat etwas bei der Anmeldung nicht funktioniert.</p>
						{/if}
						<p>Bitte wenden Sie sich per E-Mail an <a href="mailto:standort@vd.zh.ch">standort@vd.zh.ch</a>.</p>
					</div>
				</div>
			</div>
		{/if}
	</div>
</div>
